"use client";
import React, {
  Fragment,
  lazy,
  Suspense,
  useRef,
} from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "../../apollo/client";
import "./layout.css";
import Header from "../../commons/Header";
import Context from "../../utils/Context";


const Chatbox = lazy(() => import("../../commons/Chatbox"));
const Footer = lazy(() => import("../../commons/Footer/Footer"));
const MessageIcon = lazy(() => import("../../images/icons/MessageIcon"));
function Layout({ children }: any) {
  const myRef = useRef(null);
  return (
    <ApolloProvider client={client}>
      <main className={` w-full mx-auto `}>
        <Fragment>
          <Context.Provider value={{ myRef }}>
            <Header myRef={myRef} />
            <div className="xl1440:pt-0 pt-header mb-[-1px]">{children}</div>
          </Context.Provider>
          <div className="fixed md:bottom-64 md:right-64 bottom-16 right-[83px] z-[60] chatboxClass">
            <Suspense fallback={<></>}>
              <Chatbox
                position={"right-[-65px] sm:right-[0px] bottom-[88px] "}
                icon={<MessageIcon />}
                className="relative"
              />
            </Suspense>
          </div>
          <Suspense fallback={<></>}>
            <Footer />
          </Suspense>
        </Fragment>
      </main>
    </ApolloProvider>
  );
}

export default Layout;
