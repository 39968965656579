import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./src/components/layout/layout";
import "./src/style/global.css";

export const wrapRootElement = ({ element, props }) => {
  if(props && props.pageContext && props.pageContext.redirect && props.pageContext.redirect[window.location.pathname]) {
    window.location.href = props.pageContext.redirect[window.location.pathname];
  }
  const isSSR = typeof window === "undefined" 
  return(
    <>
    <div
      style={{
        pointerEvents: "none",
        position: "absolute",
        top: `0`,
        width: `calc(100vw)`,
        height: `calc(90vh)`,
        maxWidth: `calc(100vw)`,
        maxHeight: `calc(90vh)`,
        zIndex: -1,
        background:'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+")'
      }}
    ></div>
        {!isSSR && <Layout {...props}>{element}</Layout>}
    
  </>
  )
};
export const replaceHydrateFunction = () => {
  return (element:any, container:any) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}